<template>
  <input
    type="number"
    :disabled="disabled"
    class="ab-input"
    v-model="val"
    @keypress="validate(event)"
    @blur="edit()"
  />
</template>
<style></style>
<script>
import { watch } from "vue";
import { ref } from "vue";

export default {
  name: "DecimalInput",
  props: ["modelValue", "max", "min", "globalMax", "globalMin"],
  emits: ["update:modelValue", "valueChanged"],
  setup(props, ctx) {
    watch(
      () => props.modelValue, // Vue 2: props.value
      (value) => {
        val.value = value;
      }
    );
    let val = ref(props.modelValue);
    function edit() {
      if (val.value == "") val.value = null;

      if (props.max && val.value && props.max <= val.value)
        val.value = props.max - 1;
      if (props.min && val.value && props.min >= val.value)
        val.value = props.min + 1;
      if (props.globalMax && val.value && props.globalMax < val.value)
        val.value = props.globalMax;
      if (props.globalMin && val.value && props.globalMin > val.value)
        val.value = props.globalMin;

      ctx.emit("update:modelValue", val);
      ctx.emit("valueChanged", val);
    }
    function validate(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
    return { val, edit, validate };
  },
};
</script>
